import React from 'react'

import * as styles from '../../styles/modules/home/header.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const Header = ({ videoUrl, gatsbyImage }) => {
  let isDesktop = false
  if (typeof window !== 'undefined') {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    isDesktop = vw >= 640
  }

  return (
    <div className={styles.container}>
      {videoUrl && isDesktop ? (
        <video className="w-full" loop={true} autoPlay={true} muted={true}>
          <source src={videoUrl} type="video/ogg" />
          <source src={videoUrl.replace('.ogv', '.webm')} type="video/webm" />
          <source src={videoUrl.replace('.ogv', '.mp4')} type="video/mp4" />
        </video>
      ) : (
        <div>
          <GatsbyImage
            className={styles.image}
            alt="header image of a man"
            image={gatsbyImage.gatsbyImageData}
          />
        </div>
      )}
    </div>
  )
}

export default Header
